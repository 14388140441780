import React from "react"
import { Col, Row } from "antd"
import { graphql } from "gatsby"
import Shell from "../components/Shell/Shell"
import Meta from "../components/Meta/Meta"
import Hero from "../components/SharpHero/SharpHero"
import BookingBar from "../components/BookingBar/BookingBar"
import FAQs from "../components/FAQs/FAQs"
import BlogReel from "../components/BlogReel/BlogReel"

const rowProps = {
  align: "middle",
  gutter: 32,
  justify: "center",
  type: "flex",
  style: {
    margin: `auto`,
    maxWidth: 1360,
  },
}

const fullColProps = {
  xs: 22,
  md: 20,
  lg: 18,
  xl: 16,
}

export default ({ data }) => {
  const { hero } = data
  const meta = {
    seo: {
      title: "FAQ | Lakeside Lodge Clemson",
      img: hero.localFile.childImageSharp.fluid.src,
      imgAlt: hero.description,
    },
    uri: "/faq/",
  }

  return (
    <Shell>
      <Meta {...meta} />
      <Hero
        caption={
          <span>
            <br />
            Frequently Asked Questions
          </span>
        }
        contentfulAsset={data.hero}
        height={`40vh`}
        imageStyle={{ filter: `brightness(0.65)` }}
      />
      <BookingBar showCTAButtons={false} />
      <Row {...rowProps}>
        <Col {...fullColProps}>
          <FAQs faqs={data.faqs.nodes} />
        </Col>
      </Row>
      <BlogReel numPosts={3} />
    </Shell>
  )
}

export const query = graphql`
  query {
    faqs: allContentfulFrequentlyAskedQuestion(
      filter: {
        business: { elemMatch: { name: { eq: "Lakeside Lodge Clemson" } } }
      }
      sort: { fields: priority, order: ASC }
    ) {
      nodes {
        category
        question
        answer {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    hero: contentfulAsset(contentful_id: { eq: "qAy3tkS9OcRETQHb7B66A" }) {
      description
      localFile {
        childImageSharp {
          fluid(jpegProgressive: true, jpegQuality: 80, webpQuality: 80) {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
    }
  }
`
