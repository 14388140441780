import React from "react"
import { Collapse, Icon, Tabs } from "antd"
import style from "./FAQs.module.scss"

export default ({ faqs }) => (
  <Tabs className={style.tabs} defaultActiveKey="0" tabPosition="top">
    {[...new Set(faqs.map(faq => faq.category))].map((cat, index) => (
      <Tabs.TabPane className={style.tabGroup} key={index} tab={cat}>
        <Collapse
          accordion
          bordered={false}
          className={style.questionGroup}
          defaultActiveKey={["0"]}
          expandIcon={({ isActive }) => (
            <Icon
              className={style.expand}
              rotate={isActive ? 90 : 0}
              type="caret-right"
            />
          )}
        >
          {faqs
            .filter(faq => faq.category === cat)
            .map((faq, index) => (
              <Collapse.Panel
                header={<span className={style.question}>{faq.question}</span>}
                key={index}
              >
                <div
                  className={style.answer}
                  dangerouslySetInnerHTML={{
                    __html: faq.answer.childMarkdownRemark.html,
                  }}
                ></div>
              </Collapse.Panel>
            ))}
        </Collapse>
      </Tabs.TabPane>
    ))}
  </Tabs>
)
